<template>
  <v-card>
    <div class="pa-5">
      <v-row class="align-center">
        <div class="mr-5 col-auto">
          <v-btn
            color="primary"
            @click="back"
          >
            <v-icon>mdi-chevron-left</v-icon>
            Retour
          </v-btn>
        </div>
      </v-row>
      <v-row class="py-4">
        <h2 class="title col mb-0">
          Tableau de bord de {{ parent.personne.nom }}
          {{ parent.personne.prenom }}
        </h2>
      </v-row>
      <v-data-table
        v-if="!loading && getLogs.length > 0"
        :headers="headers"
        :items="getLogs"
        :items-per-page="20"
        :footer-props="{
          itemsPerPageText: 'Logs par page:',
          itemsPerPageOptions: [10, 20, 50, 100, 200]
        }"
        class="elevation-1"
      />
      <div
        v-else-if="!loading"
        class="col"
      >
        <div class="row py-4 justify-center">
          Aucunne donnée disponible
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import droitsMixin from './droits.mixin';

export default {
  name: 'Logs',
  components: {},
  mixins: [
    droitsMixin,
  ],
  data: () => ({
    headers: [
      {
        text: 'Date',
        align: 'start', sortable: true,

        value: 'date',
      },
      {
        text: 'Message', sortable: true,
        value: 'message',
      },
      {
        text: 'Operation', sortable: true,
        value: 'operation',
      },
      {
        text: 'Nom', sortable: true,
        value: 'utilisateurNom',
      },
      {
        text: 'Prénom', sortable: true,
        value: 'utilisateurPrenom',
      },
      {
        text: 'Email', sortable: true,
        value: 'utilisateurEmail',
      },
    ],
  }),

  computed: {
    ...mapState({
      parent: state => state.parent,
    }),
    ...mapState('_parent/logs', {
      loading: state => state.loading,
    }),
    ...mapGetters('_parent/logs', [
      'getLogs',
    ]),
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
