<template>
  <div>
    <Logs />
  </div>
</template>
<script>
import Logs from '../../components/parent/Logs';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ParentLogsContainer',

  components: {
    Logs,
  },
  data: () => ({
    parent: null,
  }),

  computed: {
    ...mapState({
      enfants: state => state.enfants,
    }),
  },

  created() {
    let parentId = this.$route.params.parentId;
    this.loadParentInfosById({
      parentId,
    });
    this.loadLogs({ parentId });
  },

  methods: {
    ...mapActions([
      'loadParentInfosById'],
    ),
    ...mapActions('_parent/logs', [
        'loadLogs',
      ],
    ),
  },
};
</script>
